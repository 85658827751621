import React, { useEffect } from 'react';
import { Application } from '@splinetool/runtime';

const SplineCanvas = () => {
  useEffect(() => {
    const canvas = document.getElementById('canvas3d');
    const app = new Application(canvas);
    app.load('https://prod.spline.design/rRJxyTYdu8DbIkBS/scene.splinecode');

  }, []);

  return <canvas id="canvas3d" style={{ width: '100%', height: '100%' }}></canvas>;
};

export default SplineCanvas;
 