import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SplineCanvas from './Landing Page';
import MyProjects from './My Projects';
import Photos from './Photos';
import AboutMe from './About Me';


function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<SplineCanvas />} />
            <Route path="/photos" element={<Photos />} />
            <Route path="/my-projects" element={<MyProjects />} />
            <Route path="/about-me" element={<AboutMe />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
