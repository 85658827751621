import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import SoraFont from './assets/fonts/sora/Sora-Regular.ttf';
import projectImage from './assets/images/aboutme-95.png'; // Replace with your image paths
import bannerImage from './assets/images/miffy-banner.jpg'; // Add your banner image here

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Sora';
    src: url(${SoraFont}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body, input, select, textarea {
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    height: 100%; 
    background-color: rgb(230, 225, 215); 
  }

  #root, html, body {
    height: 100%; 
  }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: rgb(228,220,212); /* Ensure entire container has background */
`;

const BannerImage = styled.img`
  width: 40%;
  height: auto;
  margin-bottom: 50px;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: rgba(2,85,153,255); /* Set title color */
  margin-bottom: 50px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  height: 300px;
  box-sizing: border-box;
`;

const Image = styled.img`
  width: 45%;
  height: 100%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  width: 50%;
  padding: 20px;
  font-size: 1.2rem;
  color: #333;
  text-align: left;
`;

const ProjectTitle = styled.h2`
  color: rgba(2,85,153,255); /* Set project title color */
`;

const Projects = () => {
  return (
    <>
      <GlobalStyles />
      <PageContainer>
        <Title>My Projects</Title>
        <BannerImage src={bannerImage} alt="Banner" />
        <Container>
          <Image src={projectImage} alt="Project Image 1" />
          <TextContainer>
            <ProjectTitle>Project Title 1</ProjectTitle>
            <p>Description of project 1 goes here. This project focuses on...</p>
          </TextContainer>
        </Container>

        <Container style={{ flexDirection: 'row-reverse' }}>
          <Image src={projectImage} alt="Project Image 2" />
          <TextContainer>
            <ProjectTitle>Project Title 2</ProjectTitle>
            <p>Description of project 2 goes here. This project focuses on...</p>
          </TextContainer>
        </Container>

        <Container>
          <Image src={projectImage} alt="Project Image 3" />
          <TextContainer>
            <ProjectTitle>Project Title 3</ProjectTitle>
            <p>Description of project 3 goes here. This project focuses on...</p>
          </TextContainer>
        </Container>
      </PageContainer>
    </>
  );
};

export default Projects;